import axios from 'axios'
import { useAuthStore } from '@/stores/authStore'

const axiosInstance = axios.create({
  baseURL: '/api/',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosInstance.interceptors.request.use(
  (config) => {
    const authStore = useAuthStore()
    if (authStore.token) {
      config.headers['Authorization'] = `Bearer ${authStore.token}`
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true
      const authStore = useAuthStore()
      try {
        const response = await axiosInstance.post('auth/token/refresh/', {
          refresh: authStore.refreshToken
        })
        console.log('From Axios Interceptor, First request status was 401,response:', response)
        if (response.data && response.data.access) {
          authStore.setToken(response.data.access)
          return axiosInstance(originalRequest)
        }
        else {
          authStore.eraseLocalTokens()
          // Rediriger vers la page de connexion si nécessaire
          return Promise.reject(error)
        }
      } catch (refreshError) {
        authStore.eraseLocalTokens()
        // Rediriger vers la page de connexion si nécessaire
        return Promise.reject(refreshError)
      }
    }
    return Promise.reject(error)
  }
)

export default axiosInstance
