// stores/competitionStore.ts

import { defineStore } from 'pinia'
import type { CompetitionLite } from '@/interfaces/competition'

import { CompetitionManager } from '@/utils/competitionManager'
import { transformTunn3lData } from '@/transformers/tunn3lTransformer'

import tunn3lApi from '@/services/api/tunn3l/tunn3lApi'
import { CompetitionsService } from '@/services/api/isJudging/competitions'
import notify from '@/services/notify'

import { waitForWampConnection, wampCall, wampControllerCall } from '@/services/wamp'

import { useAuthStore } from './authStore'
import { useJudgementStore } from './judgementStore'

export const useCompetitionStore = defineStore('competitionStore', {
  state: () => ({
    competitions: [] as CompetitionLite[] | [],
    activeCompetition: null as CompetitionManager | null,
    loading: false as boolean,
    loadingCompetitions: false as boolean,
    loadingActiveCompetition: false as boolean,
    error: null as null | Error,
    initialized: false as boolean
  }),

  getters: {
    getCompetitions: (state) =>
      state.competitions,
    getActiveCompetition: (state) => state.activeCompetition,
    getTeam: (state) => (teamId: number) => {
      return state.activeCompetition?.getTeam(teamId)
    },
    getScoreDetails: (state) => (flightId: string) => {
      return state.activeCompetition?.getFlightInfo(flightId)?.score_details
    }
  },

  actions: {
    async init() {
      if (this.initialized) {
        
        return
      }
      this.loading = true
      try {
        // await waitForWampConnection() // On a plus besoin de wamp pour les competitions
        this.initialized = true
        this.loading = false
        await this.fetchCompetitions()
      } catch (error: any) {
        console.error("Erreur lors de l'initialisation du Competition Store:", error)
        this.error = error
        this.loading = false
        throw error
      }
    },

    async fetchCompetitions() {
      if (useAuthStore().user) {
        this.loadingCompetitions = true

        try {
          const competitions = await CompetitionsService.getCompetitions()

          if (Array.isArray(competitions)) {
            // const competitionLiteList: CompetitionLite[] = competitions.map(comp => ({
            //   ...comp,
            //   provider: 'isjudging',
            //   fetched: true
            // }))
            // this.competitions.push(...competitionLiteList)

            const promises = competitions.map(async (competition) => {
              try {
                if (competition.is_tunn3l_linked === true) {
                  if (!competition.tunn3l_service?.url || !competition.tunn3l_service?.auth_token || !competition.tunn3l_service?.t3_competition_token) {
                    console.error('Provider url or token not found')
                    throw new Error('Provider url or token not found')
                  }
                  const { data } = await tunn3lApi.getCompetition(
                    competition.tunn3l_service?.url!,
                    competition.tunn3l_service?.auth_token!,
                    competition.tunn3l_service?.t3_competition_token!
                  )
                  const detailedCompetition = data.results
      
                  return {
                    ...competition,
                    name: String(detailedCompetition.competition.name),
                    fetched: true // Added fetched attribute
                  } as CompetitionLite
                }
                else {
                  return {
                    ...competition,
                    fetched: true
                  } as CompetitionLite
                }

              } catch (error) {
                console.error(
                  `Erreur lors de la récupération des détails pour la compétition ${competition.id}:`,
                  error
                )
                return {
                  ...competition,
                  fetched: false // Added fetched attribute to false on error
                } as CompetitionLite
              }
            })
    
            const detailedCompetitions = await Promise.all(promises)
            this.competitions = [] as CompetitionLite[]
            this.competitions.push(...detailedCompetitions)

          } else {
            console.warn('competitions is not an array:', competitions)
          }


          
        } catch (error: any) {
          notify('Fetching competitions failed', error.message, 'error')
          this.error = error
          throw error
        } finally {
          this.loadingCompetitions = false
        }
      }
      else {
        notify('Fetching competitions failed', 'You are not logged in', 'error')
      }
    },

    async createCompetition(formData: any) {
      console.log('formData from createCompetition', formData)
      // CompetitionService.createCompetition(competition)
    },

    async fetchActiveCompetition(competition_id: string) {
      this.loadingActiveCompetition = true

      const competitionDetails = this.competitions?.find(
        (comp) => comp.id === competition_id
      )

      try {
        let competition_datas = null

        if (competitionDetails) {
          switch (competitionDetails.is_tunn3l_linked) {
            case true: {
              if (
                !competitionDetails.tunn3l_service?.url ||
                !competitionDetails.tunn3l_service?.auth_token ||
                !competitionDetails.tunn3l_service?.t3_competition_token
              ) {
                console.error('Provider url or token not found')
                this.loadingActiveCompetition = false
                throw new Error('Provider url or token not found')
              }

              const response = await tunn3lApi.getCompetition(
                competitionDetails.tunn3l_service?.url!,
                competitionDetails.tunn3l_service?.auth_token!,
                competitionDetails.tunn3l_service?.t3_competition_token!
              )
              const t3_data = response.data


              
              competition_datas = transformTunn3lData(
                t3_data.results,
                competitionDetails.tunn3l_service?.url!,
                competitionDetails.tunn3l_service?.auth_token!,
                competitionDetails.tunn3l_service?.t3_competition_token!,
                competitionDetails.tunn3l_service?.competition!
              )
              // console.log(
              //   'competition_datas from ' +
              //     provider +
              //     ' : ' +
              //     JSON.stringify(competition_datas, null, 2)
              // )

              this.activeCompetition = new CompetitionManager(competition_datas)
              this.error = t3_data.error
              break
            }

            case false: {
              this.activeCompetition = null
              this.error = new Error("Competition is not linked to tunn3l")

              //   const response = await CompetitionsService.getCompetition(Number(token))
              //   const ij_data = response.data

              //   competition_datas = {
              //     // ...ij_data,
              //     id: parseInt(ij_data.id),
              //     name: ij_data.name,
              //     configuration: ij_data.configuration,
              //     dynamr_flights: ij_data.dynamr_flights,
              //     provider: 'isjudging',
              //     competition_id: ij_data.id,
              //     provider_url: '',
              //     provider_token: '',
              //     token: ij_data.id,
              //     events: ij_data.events
              //   }

              //   this.activeCompetition = new CompetitionManager(competition_datas)
              //   this.error = ij_data.error
              break
            }
            default:
              console.error('Is this tunn3l linked ?')
          }
        } else {
          this.activeCompetition = null
          notify('Fetching competition failed', 'Competition not found', 'error')
        }
      } catch (error) {
        notify('Fetching competition failed', 'Server Down ? ', 'error')
        this.error = error as Error
        throw error
      } finally {
        this.loadingActiveCompetition = false
      }
    },

    async refreshCompetitionData() {
      if (!this.activeCompetition) {
        return
      }

      try {
        this.fetchActiveCompetition(
          this.activeCompetition.getCompetitionData().id
        )
      } catch (error) {
        notify('Fetching competition failed', 'Server Down ? ', 'error')
        this.error = error as Error
        throw error
      }
    },

    async createFlight(provider_flight_id: string) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }

      const flight = this.activeCompetition.getFlightInfo(provider_flight_id)
      if (!flight) {
        throw new Error('Flight not found')
      }

      const eventJudges = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === flight.event_id)?.judges as Record<
        string,
        { name: string }
      >

      const judges: Record<string, string > = {}

      for (let i = 0; i < Object.keys(eventJudges).length; i++) {
        const key = `wm_${i}`
        judges[key] = eventJudges[i].name
      }

      const config = {
        event: {
          is_tunn3l_linked: this.activeCompetition.getCompetitionData().is_tunn3l_linked,
          tunn3l_service: this.activeCompetition.getCompetitionData().tunn3l_service,
          competition_id: flight.competition_id,
          event_id: flight.event_id
        },
        round_id: flight.round,
        battle_id: flight.battle,
        round_number: flight.round_number,
        battle_number: flight.battle_number,
        flight_number: flight.number,
        team: flight.team,
        provider_flight_id: provider_flight_id,
        discipline: flight.discipline,
        flight_type: flight.flight_type,
        judges: judges,
        judges_details: eventJudges,
        draw: flight.draw,
        ...flight.configuration, // Ajoute toutes les clés/valeurs de flight.configuration
        ...(flight.configuration.bust_value !== undefined && {
          bust: flight.configuration.bust_value // Ajoute bust si bust_value existe
        }),
        ...(flight.configuration.skip_value !== undefined && {
          skip: flight.configuration.skip_value // Ajoute skip si skip_value existe
        })
      }

      // // wampControllerCall('round_' + flight.flight_type, 'create', configuration)
      // wampCall('competition.dist.round.create', [config])
      
      let controller = ''

      switch (flight.discipline.toLowerCase()) {
        case 'fs4':
        case 'fs8':
        case 'vfs':
          controller = 'relative_work_ng'
          break
        default:
          switch (flight.flight_type.toLowerCase()) {
            case 'speed':
              controller = 'round_speed'
              break
            case 'free':
            case 'compulsory':
              controller = 'round_free'
              break
            // Weembinamics2024
            case 'standard':
              controller = 'relative_work_ng'
              break
            // Fin Weembinamics2024
            default:
              notify('Create flight failed', 'Unknown flight type', 'error')
              throw new Error('Unknown flight type')
          }
      }      
      wampControllerCall(controller, 'create', config)
    },

    async createFreeBattleJudgement(eventId: number, roundId: number, battleId: number) {
      if (!this.activeCompetition) {
        throw new Error('No active competition')
      }
      const eventJudges = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === eventId)?.judges

      const teams = this.activeCompetition
        .getCompetitionData()
        .events.find((event) => event.id === eventId)
        ?.rounds.find((round) => round.id === roundId)
        ?.battles.find((battle) => battle.id === battleId)?.teams

      useJudgementStore().createFreeBattleJudgement(eventId, roundId, battleId, eventJudges, teams)
    }
  }
})
