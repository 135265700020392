import { wampCall } from './wamp'

export const videoClient = {
  time: (type: string) => {
    return wampCall(type + '.review.video.time')
  },

  goto: (type: string, value: number) => {
    return wampCall(type + '.review.video.goto', [value])
  },

  playPause: (type: string) => {
    return wampCall(type + '.review.video.playpause')
  },

  speedUp: (type: string) => {
    return wampCall(type + '.review.video.speedup')
  },

  speedDown: (type: string) => {
    return wampCall(type + '.review.video.speeddown')
  },

  speed: (type: string, value: number) => {
    return wampCall(type + '.review.video.speed', [value])
  }
}
