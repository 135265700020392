// transformers/tunn3lTransformer.ts
import type {
  Competition,
  Event,
  Round,
  Battle,
  Flight,
  Team,
  TeamLite,
  Tunn3lService
} from '@/interfaces/competition'
import type { ScoresObject, SpeedScoreObject, FSScoreObject } from '@/interfaces/tunn3l'

function mapDiscipline(discipline: string): Event['discipline'] {
  switch (discipline) {
    case 'dynamic_solo':
      return 'solospeed'
    case 'dynamic_2w':
      return 'd2w'
    case 'dynamic_4w':
      return 'd4w'
    case 'fs_4w':
      return 'fs4'
    case 'fs_8w':
      return 'fs8'
    case 'vfs_4w':
      return 'vfs'
    case 'freestyle_solo':
      return 'freestyle'
    default:
      return ''
  }
}

function mapFormatType(discipline: string): Event['format_type'] {
  switch (discipline) {
    case 'dynamic_solo':
    case 'dynamic_2w':
    case 'dynamic_4w':
      return 'qualifications-tournament_bracket'
    case 'fs_4w':
      return 'standard_rounds' // ligne a supprimer
    case 'fs_8w':
    case 'vfs_4w':
    case 'freestyle':
      // Weembinamics2024
      // return 'standard_rounds'
      return 'qualifications-tournament_bracket'
      // Fin Weembinamics2024
    default:
      return 'standard_rounds'
  }
}

// function mapFormatType(
//   discipline: string,
//   nb_rounds: number,
//   nb_rounds_qualif: number
// ): Event['format_type'] {
//   if (discipline === 'freestyle') {
//     return 'standard_rounds'
//   }
//   if (nb_rounds === 0 && nb_rounds_qualif > 0) {
//     return 'qualifications-tournament_bracket'
//   } else {
//     return 'standard_rounds'
//   }
// }

function mapFlightType(type: string): Round['flight_type'] {
  switch (type) {
    case 'S':
      return 'speed'
    case 'F':
      return 'standard'
    case 'C':
      return 'compulsory'
    case 'R':
      return 'standard'
    default:
      return ''
  }
  // Weembinamics2024
  // switch (type) {
  //   case 'S':
  //     return 'speed'
  //   case 'F':
  //     return 'free'
  //   case 'C':
  //     return 'compulsory'
  //   case 'R':
  //     return 'standard'
  //   default:
  //     return ''
  // }
  // Fin Weembinamics2024
}

const configurationMap: Record<string, Record<string, any>> = {
  dynamic_solo: {
    speed: {
      bust_value: 3,
      moves_per_page: 4,
      page_count: 1,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake', page: 1 },
        { type: 'connector', name: 'Connector', page: 1 },
        { type: 'vertical', name: 'Vertical', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'mixer', name: 'Mixer 2', page: 1 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' }
        ]
      }
    },
    // Weembinamics2024
    standard: {
      ffp_event_id: [1238, 1612], // id_event_spe vr8
      round_time: 50,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
    // Fin Weembinamics2024
  },
  dynamic_2w: {
    speed: {
      ffp_event_id: [1238, 1612], // id_event_spe d2w
      bust_value: 5,
      moves_per_page: 3,
      page_count: 3,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake 1', page: 1 },
        { type: 'connector', name: 'Connector 1', page: 1 },
        { type: 'vertical', name: 'Vertical 1', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'snake', name: 'Snake 2', page: 2 },
        { type: 'connector', name: 'Connector 2', page: 2 },
        { type: 'vertical', name: 'Vertical 2', page: 2 },
        { type: 'mixer', name: 'Mixer 2', page: 2 },
        { type: 'snake', name: 'Snake 3', page: 3 },
        { type: 'connector', name: 'Connector 3', page: 3 },
        { type: 'vertical', name: 'Vertical 3', page: 3 },
        { type: 'mixer', name: 'Mixer 3', page: 3 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ]
      }
    },
    free: {
      duration: 60
    },
    // Weembinamics2024
    standard: {
      ffp_event_id: [1238, 1612], // id_event_spe vr8
      round_time: 35,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
    // Fin Weembinamics2024
  },
  dynamic_4w: {
    speed: {
      ffp_event_id: [1238, 1612], // id_event_spe d2w
      bust_value: 5,
      moves_per_page: 3,
      page_count: 3,
      skip_value: 20,
      flight_structure: [
        { type: 'entrance', name: 'Entrance' },
        { type: 'snake', name: 'Snake 1', page: 1 },
        { type: 'connector', name: 'Connector 1', page: 1 },
        { type: 'vertical', name: 'Vertical 1', page: 1 },
        { type: 'mixer', name: 'Mixer 1', page: 1 },
        { type: 'snake', name: 'Snake 2', page: 2 },
        { type: 'connector', name: 'Connector 2', page: 2 },
        { type: 'vertical', name: 'Vertical 2', page: 2 },
        { type: 'mixer', name: 'Mixer 2', page: 2 },
        { type: 'snake', name: 'Snake 3', page: 3 },
        { type: 'connector', name: 'Connector 3', page: 3 },
        { type: 'vertical', name: 'Vertical 3', page: 3 },
        { type: 'mixer', name: 'Mixer 3', page: 3 },
        { type: 'exit', name: 'Exit' }
      ],
      bust_type: {
        entrance: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        snake: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        connector: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        vertical: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Ring', ref: 'ri' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        mixer: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ],
        exit: [
          { name: 'Center Line', ref: 'cl' },
          { name: 'Side Line', ref: 'sl' },
          { name: 'Execution', ref: 'ex' },
          { name: 'Synchro', ref: 'sy' }
        ]
      }
    },
    free: {
      duration: 90
    },
    // Weembinamics2024
    standard: {
      ffp_event_id: [1238, 1612], // id_event_spe vr8
      round_time: 35,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
    // Fin Weembinamics2024
  },
  freestyle_solo: {
    compulsory: {
      duration: 45
    },
    free: {
      duration_min: 75,
      duration_max: 90,
      duration: 60 // Pour les nationaux British
    }
  },
  fs_4w: {
    standard: {
      round_time: 35,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
  },
  fs_8w: {
    standard: {
      round_time: 50,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
  },
  vfs_4w: {
    standard: {
      round_time: 35,
      bust_type: [
        { name: 'Incomplete formation', ref: 'F' },
        { name: 'Incomplete separation', ref: 'S' },
        { name: 'Incomplete inter', ref: 'I' },
        { name: 'Grip Error', ref: 'G' },
        { name: 'Omission', ref: 'O' }
      ],
    }
  }
}

function mapConfiguration(discipline: string, flightType: string): any {
  return configurationMap[discipline]?.[flightType] || {}
}

function retriveTeam(teams: Team[], teamId: number): Team | null {
  return teams.find((team) => team.id === teamId) || null
}

function convertToTeamLite(team: Team): TeamLite {
  const {
    is_unranked,
    qualifications_score,
    final_score,
    qualifications_ranking,
    final_ranking,
    ...teamLite
  } = team
  return teamLite
}

function createBattle(battle: any, discipline: string, index: string): Battle {
  const flightType = mapFlightType(battle.type)
  const teamsInBattle = Object.values(battle.teams).filter((team: any) => team.id !== '0')
  return {
    id: parseInt(battle.id),
    number: parseInt(index) + 1,
    flight_type: flightType,
    configuration: mapConfiguration(discipline, flightType),
    description: '',
    draw: [],
    teams: teamsInBattle.map((team: any) => ({
      id: parseInt(team.id),
      number: parseInt(team.num),
      name: team.name,
      coefficient: 1, // Not provided in Tunn3l data
      country: { code: team.country, name: '' },
      qualifications_score: null, // Not provided in Tunn3l data
      final_score: null, // Not provided in Tunn3l data
      qualifications_ranking: null, // Not provided in Tunn3l data
      final_ranking: null, // Not provided in Tunn3l data
      is_unranked: false // Not provided in Tunn3l data
    })),
    sub_battles: [], // TODO gérer les sub_battles pour isjudging ( A supprimer ? )
    flights: []
  }
}

function createFlight(
  eventId: number,
  roundId: number,
  battleId: number | null,
  flightNumber: number,
  team: Team | null,
  score: number | null,
  score_details: SpeedScoreObject | FSScoreObject | null,
  draw: string[]
): Flight {
  const team_lite = team ? convertToTeamLite(team) : null
  // Création d'un ID unique
  const provider_flight_id = battleId
    ? `${eventId}-${roundId}-${battleId}-${flightNumber}`
    : `${eventId}-${roundId}-${flightNumber}`
  return {
    id: provider_flight_id,
    round: roundId,
    battle: battleId,
    number: flightNumber,
    team: team_lite,
    score: score,
    score_details: score_details,
    draw: draw
  }
}

function updateTeamQualificationsScore(event: Event) {
  event.teams.forEach((team) => {
    const score = event.rounds
      .filter((round) => round.is_qualification || event.format_type === 'standard_rounds')
      .reduce((total, round) => {
        const flight = round.flights.find((f) => f.team?.id === team.id)
        return total + (flight?.score ? Number(flight.score) : 0)
      }, 0)

    team.qualifications_score = Number(score.toFixed(2))
  })
}

function updateTeamQualificationsRanking(event: Event) {
  const sortedTeams = [...event.teams].sort(
    (a, b) => (b.qualifications_score || 0) - (a.qualifications_score || 0)
  )

  sortedTeams.forEach((team, index) => {
    const originalTeam = event.teams.find((t) => t.id === team.id)
    if (originalTeam) {
      originalTeam.qualifications_ranking = index + 1
    }
  })
}

const findFlightScore = (
  scores: ScoresObject,
  eventId: string,
  flightRoundId: string,
  matchId: string,
  teamId: string,
  flightType: string
): any => {
  if (Object.keys(scores).length > 0) {
    const eventScores = scores[Number(eventId)]
    if (!eventScores) return { score: null, score_details: null }

    const roundScores = eventScores[Number(flightRoundId)]
    if (!roundScores) return { score: null, score_details: null }

    const teamScore = roundScores[Number(teamId)]
    if (!teamScore) return { score: null, score_details: null }

    let score = null
    let score_details = null

    if (flightType === 'speed') {
      if ('calculated_final_time' in teamScore && 'time' in teamScore) {
        score = Number(Number(teamScore.calculated_final_time).toFixed(3))
        score_details = {
          calculated_final_time: Number(Number(teamScore.calculated_final_time).toFixed(3)),
          time: Number(Number(teamScore.time).toFixed(3))
        } as SpeedScoreObject
      }
    }
    if (flightType === 'standard') {
      if ('score' in teamScore && 'nb_attempts' in teamScore && 'cumul' in teamScore) {
        score = Number(teamScore.score)
        score_details = {
          nb_attempts: Number(teamScore.nb_attempts),
          score: Number(teamScore.score),
          cumul: Number(teamScore.cumul)
        } as FSScoreObject
      }
    }
    if (flightType === 'free') {
      if ('score' in teamScore) {
        score = Number(teamScore.score)
      }
    }
    return { score, score_details }
  }
  return { score: null, score_details: null }
}

export function transformTunn3lData(
  data: any,
  t3_url: string,
  t3_auth_token: string,
  t3_competition_token: string,
  ij_competition_id: string
): Competition {

  const judges = data.judges || []
  const scores = data.scores || {} as ScoresObject
  const draws = data.draws || []

  const tunn3lService = {
    name: data.competition.name,
    auth_token: t3_auth_token,
    url: t3_url,
    t3_competition_token: t3_competition_token,
    t3_competition_id: data.competition.id,
    competition: ij_competition_id
  } as Tunn3lService
  // TODO : gérer le handifly
  // Les rounds ne sont pas formatés pareil
  const events: Event[] = Object.values(data.disciplines).filter((discipline: any) => discipline.name !== 'Handifly').map((discipline: any) => {
    const formatType = mapFormatType(discipline.type)
    const eventId = parseInt(discipline.id) as number

    const eventTeamsList: Team[] = Object.values(discipline.teams).map((team: any) => ({
      id: parseInt(team.id),
      number: parseInt(team.num),
      name: team.name,
      coefficient: 1,
      country: { code: team.country, name: '' },
      qualifications_score: null,
      final_score: null,
      qualifications_ranking: null,
      final_ranking: null,
      is_unranked: false
    }))

    const eventJudges = Object.values(judges)
      .filter((judge: any) => judge.discipline_id === eventId.toString() && judge.name !== '')
      .map((judge: any) => ({
        id: judge.id,
        name: judge.name,
        is_event_judge: judge.is_event_judge === '1' ? true : false
      }))


    const number_of_qualif_rounds: Number = Object.values(discipline.rounds)
      .filter((round: any) => round.qualif === '1' ).length

    const rounds: Round[] = Object.entries(discipline.rounds) // Utilisation de Object.entries pour accéder à la clé et à l'objet
      .filter(([key, round]: [string, any]) => round.type !== 'SC' && round.id) // On ne prend pas en compte les Speed Checks et on nettoie les Rounds Fantomes
      .map(([key, round]: [string, any], index: number) => {
        let round_qualification: boolean;
        let round_tournament: boolean;
        let roundNumber: number;

        // Utilisation de la clé comme ID si round.id n'est pas défini
        const roundId = round.id || key;

        if (formatType === 'qualifications-tournament_bracket') {
          if (round.qualif) {
            round_qualification = round.qualif === '1'
            round_tournament = round.qualif === '0'
          }
          else {
            round_qualification = false
            round_tournament = true
          }
        } else {
          round_qualification = true
          round_tournament = false
        }

        if (round_tournament) {
          roundNumber = Number(round.step) + Number(number_of_qualif_rounds)
        } else {
          roundNumber = Number(round.step)
        }

        let battles: Battle[] = []
        let flights: Flight[] = []
        let configuration = {}

        if (formatType === 'qualifications-tournament_bracket' && round_tournament) {
          if (round.matchs) {
            battles = Object.entries(round.matchs).map(
              ([_, match]: [string, any], index: number) => {
                const battle = createBattle(
                  match as any,
                  discipline.type as string,
                  index.toString() as string
                )
                const teamsInBattle =
                  typeof match.teams === 'object' ? Object.values(match.teams) : []
                const battleJudgements = match.jugements

                battle.flights = teamsInBattle.map((teamData: any, index: number) => {
                  const team = retriveTeam(eventTeamsList, Number(teamData.id))
                  let score = null
                  let score_details = null // TODO : gérer les score_details
                  if (battleJudgements && teamData && teamData.id !== '0') {
                    score = battleJudgements[teamData.id]
                      ? parseFloat(battleJudgements[teamData.id].score)
                      : null
                  }
                  return createFlight(
                    eventId,
                    parseInt(roundId),
                    battle.id,
                    index + 1,
                    team ? team : null,
                    score,
                    score_details,
                    battle.draw || []
                  )
                })
                return battle
              }
            )
          }
        } else {
          configuration = mapConfiguration(discipline.type, mapFlightType(round.type))

          let draw: string[] = []
          const roundDraw = draws[roundId]
          if (roundDraw) {
            Object.values(roundDraw).map((drawItem: any) => {
              Object.values(drawItem).forEach((item: any) => {
                const prefix = item.prefix.toString().toUpperCase()
                if (!isNaN(prefix) && ['fs_4w', 'fs_8w', 'vfs_4w'].includes(discipline.type)) {
                  draw.push(prefix, prefix)
                } else {
                  draw.push(prefix)
                }
              })
            })
          }

          flights = eventTeamsList.map((team, index) => {
            const { score, score_details } = findFlightScore(
              scores,
              eventId.toString(),
              roundId.toString(),
              '0',
              team.id.toString(),
              mapFlightType(round.type)
            )

            return createFlight(
              eventId,
              parseInt(roundId),
              null,
              index + 1,
              team,
              score,
              score_details,
              draw
            )
          })
        }

        // return the round object
        return {
          id: parseInt(roundId),
          // number: index + 1, on ne se sert plus de l'id, qui n'est pas forcément dans l'ordre mais du step
          number: roundNumber,
          is_qualification: round_qualification,
          is_tournament: round_tournament,
          flight_type: mapFlightType(round.type),
          configuration: configuration,
          description: round.name ? round.name : 'Round ' + (index + 1),
          draw: round.draw || [],
          teams: [], // This will be populated later if needed
          flights: flights,
          battles: battles,
          event: eventId
        }
      })

    // return the event object
    const event: Event = {
      id: parseInt(discipline.id),
      name: discipline.name,
      discipline: mapDiscipline(discipline.type),
      category: '',
      format_type: formatType,
      teams: Object.values(discipline.teams).map((team: any) => ({
        id: parseInt(team.id),
        number: parseInt(team.num),
        name: team.name,
        coefficient: 1, // Not provided in Tunn3l data
        country: { code: team.country, name: '' },
        qualifications_score: null, // Not provided in Tunn3l data
        final_score: null, // Not provided in Tunn3l data
        qualifications_ranking: null, // Not provided in Tunn3l data
        final_ranking: null, // Not provided in Tunn3l data
        is_unranked: false // Not provided in Tunn3l data
      })),
      rounds: rounds,
      qualifications_sorting_round: null,
      event_judge: null,
      format: null,
      judges: eventJudges
    }

    updateTeamQualificationsScore(event)
    updateTeamQualificationsRanking(event)

    return event
  })

  // return the competition object
  return {
    id: ij_competition_id,
    name: data.competition.name,
    // provider: provider,
    // provider_url: provider_url,
    // provider_token: provider_token,
    // token: token,
    competition_id: ij_competition_id,
    configuration: data.competition.configuration,
    dynamr_flights: data.competition.dynamr_flights,
    events: events,
    is_tunn3l_linked: true,
    tunn3l_service: tunn3lService
  }
}
